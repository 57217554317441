.addtransaction-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.addtransaction-container > form > div {
  display: flex;
  flex-direction: column;
}
form > div > label {
  font-weight: bold;
  margin: 5px 0;
  color: white;
}
form > div > input {
  border: 1px solid #dedede;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px;
  outline: none;
}
.btn {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  background-color: rgb(55, 194, 0);
  color: white;
  border-radius: 2px;
  border: none;
  font-size: 16px;
}

@media screen and (max-width: 998px) {
  .addtransaction-container {
    width: 80%;
  }
}
